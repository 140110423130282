function About() {
    return (
        <div class="flex flex-col items-center" id="about">
            <p class="text-2xl">About me</p>
            <div class="flex flex-col text-lg md:text-xl mt-6 w-5/6 md:w-2/3 text-justify">
                <p>
                    I have always been interested in computers and IT since I was teenager and saw my brother install Linux distros on the family computer. So naturally, I decided to study IP law.
                </p>
                <p class="mt-4">
                    After a number of experiences working in legal departments, I realized this industry wasn't the right fit for me because I felt I needed to be challenged more. So I decided to switch careers.
                </p>
                <p class="mt-4">
                    I loved building computers as a teenager, so now I wanted to learn about the software part. With so many resources to learn and constant evolution, web development became my revelation.
                    I learned what I know using <a href="https://www.theodinproject.com/" class="underline">The Odin Project</a>, an open source web development curriculum that is highly regarded and that produces strong web developers. 
                </p>
                <p class="mt-4">
                    In a matter of months, I learned new languages and frameworks, but most of all, I really enjoyed it. There's no better feeling than the thrill of finding the solution to a problem that keeps you up at night.
                </p>
                <p class="mt-4">
                    I'm ready to put my new skills to use as a junior software developer, and I'm available to chat about employment opportunities. 
                </p>
            </div>
        </div>
    );
}

export default About