import Intro from "./components/intro"
import WorkIntro from "./components/workIntro";
import Works from "./components/works";
import About from "./components/about";
import Contact from "./components/contact";
import Footer from "./components/footer";


function App() {
  return (
    <div>
      <Intro />
      <div class="container mx-auto mt-20">
        <WorkIntro />
        <Works />
        <hr class="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <About />
        <hr class="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
