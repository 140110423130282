function WorkIntro () {
    return (
         <div class="flex flex-col items-center" id="works">
            <h2 class="text-4xl underline underline-offset-8 decoration-red-800 decoration-4">Works</h2>
            <p class="md:px-4 w-5/6 md:w-96 mt-8 text-lg text-justify">This section is a collection of my projects. They range from simple games to complex web apps. I am invested on taking any challenge: whatever its size.</p>
        </div>
    )
}



export default WorkIntro